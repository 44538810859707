/* slide-up */

.slide-up-enter {
  /* z-index: 1; */
  transform: translateY(-100%);
}

.slide-up-enter-active {
  transform: translateY(0);
  transition: transform 300ms;
}

.slide-up-exit {
  transform: translateY(0);
}

.slide-up-exit-active {
  /* z-index: unset; */
  transform: translateY(-100%);
  transition: transform 300ms;
}

/* fade */

.fade-enter {
  opacity: 0;
  /* z-index: 1; */
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  /* z-index: unset; */
  transition: opacity 300ms;
}
